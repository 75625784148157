* {
  box-sizing: border-box;
}

body {
  margin: 3rem;
  font-family: 'Merriweather', sans serif !important;
  --bs-body-font-family: 'Merriweather', sans serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "~bootstrap-icons/font/bootstrap-icons";
