.row {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.section-title {
    text-transform: uppercase;
    font-size: 1.6rem;
    text-underline-offset: 1.5rem;
    margin-bottom: 2rem;
    text-decoration: underline #5f7697;
}

.personal-info-section {
    margin: 16px 0;
}

i {
    margin-right: 8px;
    color: #808790;
}

.education-degree {
    color: #5f7697;
}

.experience-title {
    color: #5f7697;
    font-weight: 900!important;
}

.experience-item > div {
    line-height: 2rem;
}
.sw-developer {
    display: none;
}
.hero-title {
    font-size: 1rem;
}
@media screen and (min-width: 768px) {
    .hero-title {
        font-size: 3rem;
    }
}
@media (min-width: 920px)  {
    .hero-title {
        font-size: 4.5rem;
    }

    .sw-developer {
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .border .info-section {
        border-right: solid 3px !important;
        border-bottom: 0px !important;
    }
}



